import { Component, enableProdMode } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'الرئيسية', url: '/', icon: 'home' },
    { title: 'الفروع', url: '/branch', icon: 'paper-plane' },
    { title: 'الخدمات', url: '/servises', icon: 'heart' },
    { title: 'العيادات', url: '/clinics', icon: 'heart' },
  ];
 
  constructor() {

    enableProdMode();
    document.documentElement.setAttribute('dir', 'rtl');


  }
}
