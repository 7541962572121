import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'branch',
    loadChildren: () => import('./pages/branch/branch.module').then( m => m.BranchPageModule)
  },
  {
    path: 'servises',
    loadChildren: () => import('./pages/servises/servises.module').then( m => m.ServisesPageModule)
  },
  {
    path: 'servises/:id',
    loadChildren: () => import('./pages/servises/servises.module').then( m => m.ServisesPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [IntroGuard]
  },
  {
    path: 'clinics',
    loadChildren: () => import('./pages/clinics/clinics.module').then( m => m.ClinicsPageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
